import request from './request'

//工序工位
export function derverToArrive(data) {
  return request({
    url: '/index.php/filter/derverToArrive',
    method: 'post',
    data
  })
}
//采集信息
export function DataCollectionFind(data) {
  return request({
    url: '/index.php/filter/DataCollectionFind',
    method: 'post',
    data
  })
}
//照片
export function DataCollection(data) {
  return request({
    url: '/index.php/filter/DataCollection',
    method: 'post',
    data
  })
}
//照片提交审核
export function CollectionSave(data) {
  return request({
    url: '/index.php/filter/CollectionSave',
    method: 'post',
    data
  })
}
//拖运
export function ExecuteConsignment(data) {
  return request({
    url: '/index.php/filter/ExecuteConsignment',
    method: 'post',
    data
  })
}
//资料物品
export function information(data) {
  return request({
    url: '/index.php/filter/information',
    method: 'post',
    data
  })
}
//开始托运
export function setOff(data) {
  return request({
    url: '/index.php/filter/setOff',
    method: 'post',
    data
  })
}


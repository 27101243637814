<template>
    <div>
        <div class="header" >
           
            <span @click="$emit('CarCloes')"></span>
            <!-- <span>开车拖车</span> -->
            <!-- <span @click="islogout">退出</span> -->
        </div>
        <div class="box">
            <!-- border="1" cellspacing=0 -->
            <table class="table_box" width="100%" cellspacing=0  align="center">
                <tr>
                    <th>资料物品</th>
                    <th>数量</th>
                    <th v-if="status == 'isAudit'">应收数量</th>
                    <th >实收数量</th>
                    <th>缺失备注</th>
                </tr>
                <tr align="center" v-for="(item,index) in tableData" :key="index">
                    <td>{{item.short_name}}</td>
                    <td>{{item.num}}</td>
                    <td > 
                        <span v-if="status == 'isAudit'"> {{item.realNum}}</span>
                        <van-field v-if="status == 'isTuoYun'" v-model="item.realNum" oninput="value=value.replace(/[^\d]/g,'')" type="number" placeholder="请填写" />
                    </td>
                    <td  v-if="status == 'isAudit'"> <van-field v-model="item.receiptsNum" oninput="value=value.replace(/[^\d]/g,'')" type="number"  placeholder="请填写" /></td>
                    <td> <van-field v-model="item.remark" placeholder="请填写"  /></td>
                </tr>
            </table>
            <div class="liImage">
                <p>请拍摄拖车里程表：</p>
                <div>
                    <UploadImg ref="business_license" :maxCount="1" />
                </div>
                <p>
                    <van-field v-model="record" label-width="65%" type="number"  onKeypress="return(/[\d\.]/.test(String.fromCharCode(event.keyCode)))" label="请填写拖车起运里程表数字：" placeholder="填写里程表数字"  />
                </p>
               
                <p v-if="status == 'isTuoYun'">
                    <van-field v-model="end_position" label-width="65%" label="终点位置：" placeholder="填写终点位置"  />
                    <van-field v-model="contact_person" label-width="65%" label="联系人："  />
                    <van-field v-model="phone" label-width="65%" label="联系方式："   />
                </p>
                
                <p v-if="status == 'isAudit'">
                    <van-field v-model="haul_weight" label-width="65%" label="车辆重量（吨）：" type="number"  onKeypress="return(/[\d\.]/.test(String.fromCharCode(event.keyCode)))" placeholder="填写里车辆重量"  />
                </p>
                <div>
                    请交车人签字：
                    <br>
                    <van-button type="warning" @click="chu">清除</van-button>
                    <br>
                    <div v-if="qianming" class="img"> 
                        <van-image  :src="qianming" />
                    </div>
                    <div  v-if="!qianming">
                        <signature ref="tureRef" @isFile="isFile"  />
                    </div>
                   <!-- <signature ref="tureRef" @isFile="isFile" /> -->
                </div>
            </div>
            <div>
                <van-button v-if="$route.query.ids == 1 || status == 'isTuoYun'" type="primary" size="large" @click="submit">提交</van-button>
                <van-button v-if="$route.query.ids == 2" type="primary" size="large" @click="closeP">关闭</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import UploadImg from '@/components/upload_img'
import signature from '@/components/signature'
import { encipherMent, encryptDecode } from '@/utils/encrypt'
import {information,setOff} from '@/api/send.js'
import {checkInformation,checkInformationSubmit} from '@/api/audit.js'
import {is_post} from '@/Http/api'
    export default {
        props:['status'],
        components: {
            UploadImg,signature
        },
        data() {
            return {
                value:'',
                isbase64:"",
                record:"",
                end_position:"",
                tableData:[],
                pageId:"",
                phone:"",
                contact_person:"",
                qianming:'',
                haul_weight:'',
            }
        },
        methods: {
             //清除画布签名
             chu(){
                if(this.qianming){
                    this.qianming = ''
                }else{
                    this.$refs.tureRef.clearSignImg()
                }
            },
            //关闭
            closeP(){
                if(this.$route.query.url == 'tuoyun'){
                    this.$router.push({path:'/PreserveTheSend',query:{active:4}})
                }else if(this.$route.query.url == 'acdit'){
                    this.$router.push({path:'/BqList',query:{active:1}})
                }
            },
            async getInfo(id) {
                try{
                    this.pageId = id
                    this.record = ''
                    this.end_position = ''
                    this.$nextTick(()=>{

                        this.$refs.business_license.imagesUrlList = []
                        this.$refs.business_license.images = []
                        this.$refs.tureRef.clearSignImg()
                    })
                    if(this.status == 'isTuoYun'){
                        const {data} = await information({id:id}).then(res=>res)
                        this.tableData = data.data
                        let data1 = encipherMent( JSON.stringify({id:window.localStorage.getItem('userId')}))
                        let param = new URLSearchParams();
                        param.append("value",data1);
                        const res = await is_post('index.php/index/user/_get',param).then(res=>res)
                        this.phone=res.data.phone
                        this.contact_person=res.data.realName
                    }else if(this.status == 'isAudit'){
                        const {data} = await checkInformation({id:id}).then(res=>res)
                        this.tableData = data.data.check_information
                        this.record = data.data.ending_trip_record
                        this.qianming = data.data.means_signature
                        if(data.data.ending_drive_image){
                            
                            this.$refs.business_license.images = [{url:data.data.ending_drive_image}]
                            this.$refs.business_license.imagesUrlList = [data.data.ending_drive_image]
                           
                           
                        }
                        this.tableData.forEach(i => {
                            this.$set(i,'receiptsNum',i.realNum)
                        });
                    }
                }
                catch{}
            },
            async isFile(e){
                if(this.status == 'isTuoYun'){
                    let obj = {
                        check_information:this.tableData,
                        starting_drive_image:this.$refs.business_license.imagesUrlList[0]?this.$refs.business_license.imagesUrlList[0]:'',
                        starting_trip_record:this.record,
                        owner_signature:e,
                        end_position:this.end_position,
                        id: this.pageId,
                        phone:this.phone,
                        contact_person:this.contact_person,
                    }
                    try{
                        const {data} =  await setOff(obj).then(res=>res)
                        this.$emit('CarCloesChange')
                    }catch{}
                    
                }else if(this.status == 'isAudit'){
                    let obj ={
                        id:this.pageId,
                        accept_check_information:this.tableData,
                        ending_drive_image:this.$refs.business_license.imagesUrlList[0]?this.$refs.business_license.imagesUrlList[0]:'',
                        ending_trip_record:this.record,
                        haul_weight:this.haul_weight,
                        means_signature:e,
                    }
                    try{  const {data} =  await checkInformationSubmit(obj).then(res=>res)
                    this.$router.push({path:'/BqList',query:{active:1}})}catch{}
                  
                }
            },
           submit(){
              
                if(this.qianming){
                    this.isFile(this.qianming)
                }else{this.$refs.tureRef.saveSignImg()}
            },
           
        },
    }
</script>

<style lang="scss" scoped>
.header {
    position: fixed;
    top: 0;
    z-index: 9;
    width: calc(100% - 40px);
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    display: flex;
    justify-content: space-between;
    span {
        display: inline-block;
    }
    span:nth-child(1) {
        width: 30px;
        height: 30px;
        background-image: url(../../assets/u39.png);
        background-size: 100%;
        margin-top: 8px;
    }
}
.box{
    margin-top: 55px;
    padding: 10px;
    .table_box{
        border-right:1px solid #000;border-bottom:1px solid #000;
        tr{
            display: flex;
        }
        td{
            border-left:1px solid #000;
            border-top:1px solid #000;
            flex:1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        th{border-left:1px solid #000;border-top:1px solid #000; flex:1;}
       
    }
}
.van-cell{
    padding: 0;
}
.liImage{
    margin-top: 20px;
}
.img{
    background: #eee;
    position: relative;
}
</style>